<template>
  <div
    class="mt-20 md:mt-0 px-3 md:px-12 md:pt-10 mx-auto relative"
    v-if="experience"
  >
    <MobileHeader
      :headingText="getHeaderText()"
      routeName="OpenDemosCatalog"
      class="block md:hidden"
    />
    <Heading
      :headingText="getHeaderText()"
      routeName="OpenDemosCatalog"
      class="hidden md:block"
    />
    <div
      class="text-black bg-nColorFillGray border border-nColorStrokeGray rounded-xl mt-4"
    >
      <div class="px-10">
        <p class="my-8 text-xl font-semibold">
          Piece together this jigsaw puzzle to get your message. Press
          <span class="font-bold">START</span> to begin.
        </p>
      </div>
      <div class="w-full flex justify-center jigsaw-start" v-if="!started">
        <button
          class="bg-nButtonGreen px-20 rounded-xl py-4 my-16 text-lg font-semibold uppercase tracking-wider hover:scale-105 transform transition-all duration-400 ease-in-out text-white"
          @click="initJigsaw()"
        >
          Start
        </button>
      </div>
      <div
        v-if="started"
        class="jigsaw-exp jigsaw-container relative top-0 left-0 w-full bg-nColorBlue"
      >
        <transition name="fade">
          <!-- JIGSAW CANVAS -->
          <div id="canvas-wrap" v-if="!completed">
            <canvas id="canvas"></canvas>
            <canvas class="hide" id="image"></canvas>
            <canvas class="hide" id="image-preview"></canvas>
          </div>
          <div
            class="completed h-full flex flex-col justify-around items-center"
            v-else
          >
            <p
              class="px-4 md:mx-0 text-center text-3xl font-bold text-white"
              v-if="solved"
            >
              Yay ! You took {{ diffTime }} seconds to solve this.
            </p>
            <img :src="jigsawImage" alt="" class="md:w-1/3" />
          </div>
        </transition>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
/* eslint-disable no-undef */

import { apiResource } from "@/common/app.config";
import ApiService from "@/common/api.service";
import differenceInSeconds from "date-fns/differenceInSeconds";
import obkSlugs from "@/common/slugs";
import MobileHeader from "@/components/Demo/MobileHeader.vue";
import Heading from "@/components/Demo/Heading.vue";
import Footer from "@/components/Demo/CatalogElements/DesktopFooter.vue";

export default {
  name: "Jigsaw",
  props: ["id"],
  components: { MobileHeader, Heading, Footer },
  created() {
    ApiService.get(apiResource.demo.getDetails, {
      slug: obkSlugs.demoJigsaw,
    })
      .then(({ data }) => {
        this.experience = data.data;
        this.jigsawImage = data.data.exp_details.jigsaw_image;
        const img = new Image();
        img.src = this.jigsawImage;

        img.addEventListener("load", (e) => {
          this.assignImageWidth(e.target);
        });

        this.link = data.data.link;
        return this.loadJigsawLibrary();
      })
      .then(() => {
        this.loading = false;
      })
      .catch(() => {});
  },
  mounted() {},
  data() {
    return {
      experience: null,
      jigsawImage: null,
      link: null,
      imgWidth: null,
      imgHeight: null,
      showGiveUp: false,
      startTime: null,
      endTime: null,
      diffTime: null,
      completed: false,
      loading: true,
      started: false,
      solved: false,
    };
  },
  methods: {
    loadJigsawLibrary() {
      console.log("loading...");
      return new Promise((resolve, reject) => {
        this.$loadScript("/library/experiences/jigsaw/js/sound.js")
          .then(() => {
            return this.$loadScript(
              "/library/experiences/jigsaw/js/event-emiter.min.js"
            );
          })
          .then(() => {
            return this.$loadScript(
              "/library/experiences/jigsaw/js/canvas-event.min.js"
            );
          })
          .then(() => {
            return this.$loadScript(
              "/library/experiences/jigsaw/js/canvas-puzzle.min.js"
            );
          })
          .then(() => {
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },

    initJigsaw() {
      const vue = this;
      this.started = true;
      this.startTime = new Date();

      setTimeout(() => {
        this.showGiveUp = true;
      }, 30000);

      this.$nextTick(() => {
        var sound = new game.Sound(
          "/library/experiences/jigsaw/sound/click",
          10
        );
        var jsaw = new jigsaw.Jigsaw({
          defaultImage: vue.jigsawImage,
          image: jigsaw.GET["image"],
          maxWidth:
            vue.imgWidth > window.innerWidth * 0.75
              ? window.innerWidth * 0.75
              : vue.imgWidth,
          maxHeight:
            vue.imgHeight > window.innerHeight * 0.75
              ? window.innerHeight * 0.75
              : vue.imgHeight,
          spread: 0.5,
          defaultPieces: 4,
          numberOfPieces: [10, 20, 30, 40, 50, 60, 100],
          piecesNumberTmpl: "%d Pieces",
          shuffled: true,
        });

        if (jigsaw.GET["image"]) {
          jsaw.set_image(jigsaw.GET["image"]);
        }

        jsaw.eventBus.on(jigsaw.Events.PIECES_CONNECTED, function () {
          sound.play();
        });

        jsaw.eventBus.on(jigsaw.Events.JIGSAW_COMPLETE, function () {
          vue.solved = true;
          vue.puzzleComplete();
        });
      });
    },

    puzzleComplete() {
      this.completed = true;
      this.showGiveUp = false;
      this.endTime = new Date();
      this.diffTime = differenceInSeconds(this.endTime, this.startTime);
    },

    assignImageWidth(image) {
      this.imgWidth = image.width;
      this.imgHeight = image.height;
    },
    getHeaderText() {
      return this.experience.name;
    },
  },
};
</script>

<style scoped>
@import url("/library/experiences/jigsaw/css/style.css");

.jigsaw-container {
  height: 42rem;
}

.fade-enter-from {
  opacity: 0;
}
.fade-enter-to {
  opacity: 1;
}
.fade-enter-active {
  transition: all ease-in-out 0.5s 0.5s;
}

.fade-leave-from {
  opacity: 1;
}
.fade-leave-to {
  opacity: 0;
}
.fade-leave-active {
  transition: all ease-in-out 0.5s;
}
</style>
